.paragraphBox {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    padding: 0 10px;
    margin-bottom: 20px;
}

.paragraphIconBox {
  width: 100px;
  height: 100px;
  border-radius: 50px;
  margin-bottom: 10px;
  background-color: #03142A;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.paragraphIcon {
    width: 50px;
    height: 50px;
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
  }

  .paragraphTitle {
    text-align: left;
    font-family: 'Montserrat Medium', sans-serif;
    font-weight: 900;
    font-size: 1.5em ;
    letter-spacing: 0;
    color: #03142A;
    line-height: 1.2;
    opacity: 1; 
    margin-bottom: 10px;
  }

  .paragraphSubTitle {
    text-align: center;
    font-family: 'Montserrat Regular', sans-serif;
    font-size: 1.5em ;
    letter-spacing: 0;
    color: #03142A;
    line-height: 1.2;
    opacity: 1; 
    margin-bottom: 10px;
  }

  .paragraphText {
    font-family: 'Montserrat Regular', sans-serif;
    font-size: 1em ;
    text-align: justify;
    text-justify: distribute;
    color: #03142A;
    line-height: 1.80em;
    opacity: 1;  
  }

  .paragraphLink {
    text-align: center;
    font-family: 'Montserrat Bold', sans-serif;
    font-size: .8em ;
    /* letter-spacing: 0; */
    opacity: 1;
    margin-top: 10px;
  }

  .paragraphLink a {
    color: #03142A;
  }

  /* RESPONSIVE */
@media only screen and (max-width: 800px) {
  .paragraphBox{
    padding: 0;
  }

  .paragraphIconBox {
    width: 70px;
    height: 70px;
    border-radius: 35px;
  }

  .paragraphIcon {
    width: 35px;
    height: 35px;
  }

  .paragraphText {
    font-size: .9em;
  }
}