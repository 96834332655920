.storeIcon {
  width: 55px;
  height: 55px;
  opacity: 1;
  background-repeat: no-repeat;
  background-size: contain;
  background-position-y: 100%;
}

.downloadButton {
  margin-top: 10px;
  padding: 0px 20px;
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 60px;
  background: transparent linear-gradient(180deg, #FFFFFF 0%, #F6CEE7 100%) 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #00000029;
  border-radius: 50px;
  opacity: 1;
}

.downloadButton .textContainer {
  margin: 5px 10px 20px 20px;;
}
.download {
  text-align: left;
  font: Bold 1.8em Trebuchet MS;
  letter-spacing: 0px;
  color: #03142A;
  line-height: 1;
}

.storeText {
  text-align: center;
  font: Bold 1em Trebuchet MS;
  letter-spacing: 0px;
  color: #03142A;
  opacity: 1;
  line-height: 1;
}

.menuButtonContainer {
  width: 50px;
  height: 50px;
  border-radius: 25px;
  margin-bottom: 2em;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  background: transparent linear-gradient(180deg, #F9F5F5 0%, #CCCCCC 100%) 0% 0% no-repeat padding-box;
}

.menuButtonContainer .icon {
  width: 20px;
  height: 20px;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
}

@media only screen and (max-width: 800px) {

  .downloadButton {
    margin-top: 10px;
    padding: 0px 10px;
    height: 45px;
  }

  .downloadButton .textContainer {
    margin: 3px 10px 0px 10px;
  }

  .download {
    font: Bold 1.3em Trebuchet MS;
  }

  .storeText {
    font: Bold .8em Trebuchet MS;
  }

  .storeIcon {
    width: 40px;
    height: 40px;
  }

  .menuButtonContainer {
    width: 30px;
    height: 30px;
    border-radius: 15px;
}

  .menuButtonContainer .icon {
    width: 15px;
    height: 15px;
  }
}
