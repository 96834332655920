
.logo {
  width: 100px;
  height: 90px;
  opacity: 1;
  background-repeat: no-repeat;
  background-size: contain;
  background-position-y: center;
}

.appLogoContainer .textContainer {
  margin: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.appName {
  text-align: left;
  font-family: ComfortaaBold, cursive;
  font-size: 2.5em;
  letter-spacing: 0px;
  color: #03142A;
  line-height: 1;
}

.slogan {
  text-align: center;
  font: Bold 1em Trebuchet MS;
  font-style: italic;
  letter-spacing: 0px;
  color: #4c4b4b;
  opacity: 1;
  line-height: 1;
}

@media only screen and (max-width: 579px) {

  .logo {
    width: 80px;
    height: 90px;
  }

}