.menuContainer {
    position: fixed;
    right: 0.5em;
    display: flex;
    flex-direction: column;
    height: 100vh;
    justify-content: center;
    z-index: 99;
}

@media only screen and (max-width: 579px) {
    .menuContainer {
        display: none;
    }
}