.featureShadowBox {
    position: relative;
    background-color: #03142A;
    margin-top: 50px;
    right: -150px;
    padding-right: 50px;
    bottom: -50px;
    height: 150px;
}

.featureBox {
    display: flex;
    flex-direction: row;
    justify-content: center;
    padding: 30px 20px;
    border: #03142A solid 1px;
    background-color: #00E8CF;
    z-index: 2;
    position: absolute;
    right: 130px;
    top: -50px;
    left: -120px;
}

.textBox {
    margin: 0 10px;
}

.featureIconBox {
    width: 50px;
    height: 50px;
    min-width: 50px;
    border-radius: 50px;
    margin-bottom: 10px;
    background-color: #03142A;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.featureIcon {
    width: 20px;
    height: 20px;
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
}

.title {
    text-align: right;
    font-family: 'Montserrat Bold', sans-serif;
    font-weight: 900;
    font-size: 1.5em ;
    letter-spacing: 0;
    color: #03142A;
    line-height: 1.2;
    opacity: 1; 
    margin-bottom: 10px;
}

.desc {
    font-family: 'Montserrat Light', sans-serif;
    font-size: .8em ;
    text-align: right;
    text-justify: distribute;
    color: #03142A;
    line-height: 1.5em;
    opacity: 1;  
}

/* RIGHT POSITION BOX */

.featureShadowBoxRight {
    position: relative;
    background-color: #03142A;
    margin-top: 50px;
    bottom: -50px;
    height: 150px;
    left: -150px;
    padding-left: 50px;
}

.featureShadowBoxRight .featureBox {
    left: 130px;
    right: -120px;
}

.featureShadowBoxRight .title {
    text-align: left;
}

.featureShadowBoxRight .desc {
    text-align: left; 
}

@media only screen and (max-width: 800px) {

    .featureShadowBox {
        right: -70px;
    }

    .featureBox {
        padding: 10px 10px;
        min-height: 185px;
        left: -50px;
        right: 50px;
    }

    .featureIconBox {
        width: 30px;
        height: 30px;
        min-width: 30px;
        border-radius: 15px;
    }

    .featureIcon {
        width: 15px;
        height: 15px;
    }

    .featureShadowBoxRight {
        left: -70px;
    }

    .featureShadowBoxRight .featureBox {
        padding: 10px 10px;
        min-height: 185px;
        right: -50px;
        left: 50px;
    }

}

@media only screen and (max-width: 579px) {

    .featureShadowBoxRight {
        left: -15px;
        padding-left: 0px;
        bottom: -10px;      
        height: 120px;
        margin-right: 50px;
    }

    .featureShadowBoxRight .featureBox {
        padding: 10px 10px;
        min-height: 150px;
        right: -55px;
        left: 20px;
    }
}